import { GROUPS_FETCH_GROUP_SETS } from '../actions';
import { fetchGroupSets as fetchGroupSetsApi } from '../api';
import fetchStudentsWithNoGroupPerSet from '../api/fetchStudentsWithNoGroupPerSet';
import refreshAccessToken from '../../actions/accessToken/refreshAccessToken.action';
import { currentClassRoomId as getCurrentClassRoomId } from '../../selectors/classroom';

const fetchGroupSetsStarted = (payload) => ({
  type: GROUPS_FETCH_GROUP_SETS,
  status: 'started',
  payload,
});

const fetchGroupSetsSuccess = (payload) => ({
  type: GROUPS_FETCH_GROUP_SETS,
  status: 'success',
  payload,
});

const fetchGroupSetsError = (payload) => ({
  type: GROUPS_FETCH_GROUP_SETS,
  status: 'error',
  payload,
});

const fetchGroupSets =
  (space = undefined) =>
  async (dispatch, getState) => {
    const state = getState();
    const spaceId = space || getCurrentClassRoomId(state);
    const payload = { spaceId };
    dispatch(fetchGroupSetsStarted(payload));
    try {
      const accessToken = await refreshAccessToken(
        dispatch,
        getState
      )({ spaceId });
      let groupSets = await fetchGroupSetsApi(accessToken, {
        spaceId: payload.spaceId,
      });
      const studentsWithNoGroupData = await Promise.all([
        ...groupSets.map(
          (set) =>
            new Promise((resolve) => {
              resolve(
                fetchStudentsWithNoGroupPerSet(
                  accessToken,
                  set.id,
                  payload.spaceId
                )
              );
            })
        ),
      ]);
      groupSets = groupSets.map((set, index) => ({
        ...set,
        ...studentsWithNoGroupData[index],
      }));
      dispatch(fetchGroupSetsSuccess({ groupSets }));
    } catch (error) {
      dispatch(fetchGroupSetsError({ ...payload, error }));
    }
  };

export default fetchGroupSets;
