import { isCancel } from 'axios';
import { GROUPS_FETCH_GROUP_SET } from '../actions';
import fetchStudentsWithNoGroupPerSet from '../api/fetchStudentsWithNoGroupPerSet';
import { fetchGroupSet as fetchGroupSetApi } from '../api';
import refreshAccessToken from '../../actions/accessToken/refreshAccessToken.action';
import { currentClassRoomId as getCurrentClassRoomId } from '../../selectors/classroom';

const fetchGroupSetStarted = () => ({
  type: GROUPS_FETCH_GROUP_SET,
  status: 'started',
});

const fetchGroupSetSuccess = (payload) => ({
  type: GROUPS_FETCH_GROUP_SET,
  status: 'success',
  payload,
});

const fetchGroupSetError = (payload) => ({
  type: GROUPS_FETCH_GROUP_SET,
  status: 'error',
  payload,
});

const fetchGroupSet = (groupSetId) => async (dispatch, getState) => {
  const state = getState();
  dispatch(fetchGroupSetStarted());
  try {
    const spaceId = getCurrentClassRoomId(state);
    const accessToken = await refreshAccessToken(
      dispatch,
      getState
    )({ spaceId });
    const set = await fetchGroupSetApi(accessToken, {
      groupSetId,
    });
    const { studentsWithNoGroup } = await fetchStudentsWithNoGroupPerSet(
      accessToken,
      groupSetId,
      spaceId
    );
    dispatch(fetchGroupSetSuccess({ set: { ...set, studentsWithNoGroup } }));
  } catch (error) {
    if (isCancel(error)) {
      return;
    }
    dispatch(fetchGroupSetError({ error }));
  }
};

export default fetchGroupSet;
