import { GROUP_GROUPS } from '../../constants/endpoints';
import request from './request';

const defaultGroup = {
  avatar: '',
  educators: [],
  students: [],
  hasConversation: false,
  conversationIncludesEducators: false,
};

const addGroup = async (accessToken, group) => {
  const config = {
    method: 'post',
    url: GROUP_GROUPS(),
    data: {
      group: {
        ...defaultGroup,
        ...group,
        avatar: group.avatar || '', // API Gateway (Open API 3.0) does not support nullable string value
      },
    },
  };
  const response = await request(accessToken, config);
  return response.data.group;
};

export default addGroup;
