import { GROUPS_EDIT_GROUP } from '../actions';
import { editGroup as editGroupApi } from '../api';
import fetchStudentsWithNoGroupPerSet from '../api/fetchStudentsWithNoGroupPerSet';
import refreshAccessToken from '../../actions/accessToken/refreshAccessToken.action';
import { currentClassRoomId as getCurrentClassRoomId } from '../../selectors/classroom';
import showToast from '../../toasts/operations/showToast.operation';
import {
  EDIT_GROUP_SUCCESS,
  EDIT_GROUP_ERROR,
  CREATE_CONVERSATION_SUCCESS,
  EDIT_CONVERSATION_SUCCESS,
  DELETE_CONVERSATION_SUCCESS,
} from './toasts';

const editGroupSuccess = (payload) => ({
  type: GROUPS_EDIT_GROUP,
  status: 'success',
  payload,
});

const editGroupError = (payload) => ({
  type: GROUPS_EDIT_GROUP,
  status: 'error',
  payload,
});

const EDITABLE_FIELDS = [
  'avatar',
  'educators',
  'hasConversation',
  'conversationIncludesEducators',
];

const EXTENDED_EDITABLE_FIELDS = [...EDITABLE_FIELDS, 'name', 'students'];

const getPatchPayload = (origGroup, updatedGroup) => {
  const isInstitutionManaged = !!origGroup.externalId;
  const allowedFields = isInstitutionManaged
    ? EDITABLE_FIELDS
    : EXTENDED_EDITABLE_FIELDS;

  // Educators and students must be passed as id only
  const originalGroup = {
    ...origGroup,
    educators: origGroup.educators.map((ed) => ed.id),
    students: origGroup.students.map((st) => st.id),
  };

  const updates = {
    ...originalGroup,
    ...updatedGroup,
  };

  const payload = {
    group: Object.keys(updates).reduce((acc, field) => {
      if (allowedFields.includes(field)) {
        return {
          ...acc,
          [field]: updates[field],
        };
      }
      return acc;
    }, {}),
  };

  return payload;
};

const getToastMessage = (origGroup, updatedGroup) => {
  if (
    origGroup.hasConversation === false &&
    updatedGroup.hasConversation === true
  ) {
    return CREATE_CONVERSATION_SUCCESS;
  }
  if (
    origGroup.hasConversation === true &&
    updatedGroup.hasConversation === true
  ) {
    return EDIT_CONVERSATION_SUCCESS;
  }
  if (
    origGroup.hasConversation === true &&
    updatedGroup.hasConversation === false
  ) {
    return DELETE_CONVERSATION_SUCCESS;
  }
  return EDIT_GROUP_SUCCESS;
};

const editGroup = (origGroup, group) => async (dispatch, getState) => {
  const state = getState();

  const payload = getPatchPayload(origGroup, group);

  try {
    const spaceId = getCurrentClassRoomId(state);

    const accessToken = await refreshAccessToken(
      dispatch,
      getState
    )({ spaceId });
    const { group: editedGroup } = await editGroupApi(
      accessToken,
      payload.group,
      origGroup.id
    );
    const { studentsWithNoGroup } = await fetchStudentsWithNoGroupPerSet(
      accessToken,
      editedGroup.set,
      spaceId
    );
    dispatch(
      editGroupSuccess({
        group: {
          ...editedGroup,
          students: editedGroup?.students?.length || 0,
          educators: editedGroup?.educators?.length || 0,
        },
        studentsWithNoGroup,
      })
    );
    dispatch(showToast(getToastMessage(origGroup, group)));
  } catch (error) {
    dispatch(showToast(EDIT_GROUP_ERROR));
    dispatch(
      editGroupError({ group: { ...origGroup, ...payload.group }, error })
    );
  }
};

export default editGroup;
