const addGroupReducer = (state, action) => {
  if (action.status !== 'success') {
    return state;
  }

  const {
    payload: { group },
  } = action;

  if (!state.sets[group.set]) {
    return state;
  }

  const updatedGroups = {
    ...state.groups,
    [group.id]: group,
  };

  const updatedSet = {
    ...state.sets[group.set],
  };

  if (!updatedSet.groups.includes(group.id)) {
    updatedSet.groups = [...updatedSet.groups, group.id];
  }

  return {
    ...state,
    groups: updatedGroups,
    sets: {
      ...state.sets,
      [group.set]: updatedSet,
    },
  };
};

export default addGroupReducer;
