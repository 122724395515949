import { STUDENTS_WITH_NO_GROUP } from '../../constants/endpoints';
import request from './request';

const fetchStudentsWithNoGroupPerSet = async (accessToken, setId, spaceId) => {
  const config = {
    method: 'get',
    url: STUDENTS_WITH_NO_GROUP(setId, spaceId),
  };

  const response = await request(accessToken, config);
  return response.data;
};

export default fetchStudentsWithNoGroupPerSet;
